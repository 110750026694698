<template>
  <div style="text-align: left">资讯信息</div>
  <el-form
    :inline="true"
    :model="seachForm"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
    class="lineform"
  >
    <el-form-item label="标题" size="small">
      <el-input
        v-model="seachForm.title"
        clearable
        placeholder="请输入关键词"
      ></el-input>
    </el-form-item>
    <el-form-item label="关键词" size="small">
      <el-input
        v-model="seachForm.keywords"
        clearable
        placeholder="请输入关键词"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="clearall"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      >
    </el-form-item>
  </el-form>
  <div style="display: flex; justify-content: flex-start">
    <el-button type="primary" size="mini" @click="addNews">新增资讯</el-button>
  </div>
  <el-empty v-if="tableData.length === 0" description="暂无数据"></el-empty>
  <el-table
    v-else-if="tableData.length >= 0"
    :data="tableData"
    stripe
    style="width: 100%; margin-top: 30px"
  >
    <el-table-column type="index" label="#"> </el-table-column>
    <el-table-column prop="title" label="文章标题" width="200px">
    </el-table-column>
    <el-table-column prop="keywords" label="关键词" width="150px">
      <template #default="scope">
        <el-tag>{{ scope.row.keywords }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="coverUrl" label="文章封面" width="150px">
      <template #default="scope">
        <img
          :src="imgBaseUrl + scope.row.coverUrl"
          alt=""
          style="width: 80px; height: 120px; object-fit: contain"
        />
      </template>
    </el-table-column>
    <el-table-column prop="digest" label="文章摘要" width="200">
    </el-table-column>
    <el-table-column prop="coverUrl" label="文章状态" width="220">
      <template #default="scope">
        <el-tag v-if="scope.row.adopted" size="mini" type="success"
          >展示</el-tag
        >
        <el-tag
          style="margin-left: 10px"
          v-if="scope.row.bannered"
          type="success"
          size="mini"
          >轮播</el-tag
        >
        <el-tag
          style="margin-left: 10px"
          size="mini"
          v-if="scope.row.homed"
          type="success"
          >公告</el-tag
        >
        <el-tag
          size="mini"
          style="margin-left: 10px"
          v-if="scope.row.top"
          type="success"
          >置顶</el-tag
        >
      </template>
    </el-table-column>
    <el-table-column prop="showDate" label="显示日期" width="180">
    </el-table-column>

    <el-table-column prop="address" label="操作" width="200">
      <template #default="scope">
        <div
          style="display: flex; justify-content: flex-start; flex-wrap: wrap"
        >
          <el-button
            type="text"
            class="marginleft0"
            @click="editnews(scope.row)"
            >修改</el-button
          >
          <el-button
            type="text"
            @click="swipernews(scope.row)"
            class="marginleft0"
            >轮播</el-button
          >
          <el-button type="text" @click="topnews(scope.row)" class="marginleft0"
            >置顶</el-button
          >
          <el-button
            type="text"
            @click="noicenews(scope.row)"
            class="marginleft0"
            >公告</el-button
          >
          <el-button
            type="text"
            @click="shownews(scope.row)"
            class="marginleft0"
            >展示</el-button
          >
          <el-button
            type="text"
            @click="deleteNews(scope.row)"
            class="marginleft0"
            >删除</el-button
          >
        </div>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 20, 30]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
</template>
<script>
export default {
  data() {
    return {
      seachForm: {
        keywords: '',
        title: ''
      },
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
      imgBaseUrl: '',
      tableData: [],
    }
  },
  methods: {
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/paperinfo/paperinfos/1' + '?pageSize=' + val + (this.seachForm.title.trim() === '' ? '' : '&title=' + this.seachForm.title) + (this.seachForm.keywords.trim() === '' ? '' : '&keywords=' + this.seachForm.keywords))
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async handleCurrentChange(val) {
      console.log(val)
      var str = val + '?pageSize=' + this.pageSize + (this.seachForm.title.trim() === '' ? '' : '&title=' + this.seachForm.title) + (this.seachForm.keywords.trim() === '' ? '' : '&keywords=' + this.seachForm.keywords)
      const res = await this.$http.get('/nktdec/paperinfo/paperinfos/' + str)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async onSubmit() {
      console.log(this.seachForm)
      var str = '?' + (this.seachForm.title.trim() === '' ? '' : 'title=' + this.seachForm.title) + (this.seachForm.keywords.trim() === '' ? '' : (this.seachForm.title.trim() === '' ? '' : '&') + 'keywords=' + this.seachForm.keywords)
      console.log(str)
      const res = await this.$http.get('/nktdec/paperinfo/paperinfos/1' + str)
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async clearall() {
      const res = await this.$http.get('/nktdec/paperinfo/paperinfos/1')
      console.log(res.data)
      this.seachForm.keywords = ''
      this.seachForm.title = ''
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    addNews() {
      this.$router.push('/addnews/true')
    },
    async getnews(pageno) {
      const res = await this.$http.get('/nktdec/paperinfo/paperinfos/' + pageno)
      // console.log(res)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      console.log(this.tableData)
    },
    editnews(row) {
      console.log(row)
      window.sessionStorage.setItem('News', JSON.stringify(row))
      this.$store.commit('setNews', row)
      this.$router.push('/addnews/false')
    },
    async shownews(row) {
      const res = await this.$http.put('/nktdec/paperinfo/adoptpaperinfo/' + row.id, { adopted: !row.adopted })
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('设置成功！')
        this.getnews(1)
      }
    },
    async swipernews(row) {
      const res = await this.$http.put('/nktdec/paperinfo/bannerpaperinfo/' + row.id, { bannered: !row.bannered })
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('设置成功！')
        this.getnews(1)
      }
    },
    async noicenews(row) {
      const res = await this.$http.put('/nktdec/paperinfo/homepaperinfo/' + row.id, { homed: !row.homed })
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('设置成功！')
        this.getnews(1)
      }
    },
    async topnews(row) {
      const res = await this.$http.put('/nktdec/paperinfo/toppaperinfo/' + row.id, { topped: !row.topped })
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('设置成功！')
        this.getnews(1)
      }
    },
    async deleteNews(row) {
      console.log(row)
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/paperinfo/paperinfo/' + row.id)
        console.log(res)
        if (res.data.code === '1') {
          this.getnews(1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
      })
    },
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '资讯管理',
        index: '/newsmange'
      },
      {
        name: '资讯列表',
        index: '/newsinfo'
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getnews(1)
    // this.imgBaseUrl = this.$http.defaults.baseURL + 'nktdec/uploader/images/'
    this.imgBaseUrl = 'http://nktdec.ctvideo.cn/images/'
  }
}
</script>
<style scoped>
.marginleft0 {
  margin-left: 0 !important;
  width: 25%;
}
</style>
