<template>
  <div style="text-align: left">求购信息</div>
  <el-form
    :model="buyform"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    style="margin-top: 30px"
    class="demo-ruleForm"
  >
    <el-form-item
      label="信息标题"
      size="small"
      prop="caption"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-input
          style="width: 230px"
          clearable
          v-model="buyform.caption"
          placeholder="请输入信息标题"
        ></el-input>
      </div>
    </el-form-item>

    <el-form-item
      label="信息图片"
      prop="goodsPic"
      class="goodpic"
      style="width: 50%"
    >
      <el-upload
        action="#"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
        :auto-upload="false"
        accept=""
        :file-list="teamlist"
        :on-change="filechange"
        :limit="3"
        :on-exceed="handleexceed"
      >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >建议宽高比11：8</span
      >
    </el-form-item>
    <el-form-item
      label="关键词"
      size="small"
      prop="keywords"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-input
          style="width: 230px"
          clearable
          v-model="buyform.keywords"
          placeholder="请输入关键词"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="产品名称"
      size="small"
      prop="keywords"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-input
          style="width: 230px"
          clearable
          v-model="buyform.productName"
          placeholder="请输入产品名称"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="产品数量"
      size="small"
      prop="keywords"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-input
          style="width: 230px"
          clearable
          v-model="buyform.number"
          placeholder="请输入产品数量"
        ></el-input>
        <div style="margin-left: 10px">吨</div>
      </div>
    </el-form-item>
    <el-form-item
      label="规格要求"
      size="small"
      prop="keywords"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-input
          style="width: 230px"
          clearable
          v-model="buyform.standard"
          placeholder="请输入规格要求"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="期望地区"
      size="small"
      prop="keywords"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-input
          style="width: 230px"
          clearable
          v-model="buyform.region"
          placeholder="请输入期望地区"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="联系人" size="small" prop="linkman" style="width: 50%">
      <div style="display: flex">
        <el-input
          style="width: 230px"
          clearable
          v-model="buyform.linkman"
          placeholder="请输入联系人"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="联系方式"
      size="small"
      prop="linkman"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-input
          style="width: 230px"
          clearable
          v-model="buyform.telephone"
          placeholder="请输入联系方式"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="备注信息" size="small" prop="note" style="width: 50%">
      <div style="display: flex">
        <el-input
          style="width: 230px"
          clearable
          v-model="buyform.note"
          placeholder="请输入备注信息"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="是否展示" prop="show" style="width: 50%">
      <div class="displayflex" style="margin-top: 10px">
        <el-switch
          v-model="buyform.show"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </div>
    </el-form-item>
    <el-form-item label="供应描述" prop="content" style="width: 80%">
      <vue-ueditor-wrap
        v-model="buyform.content"
        :config="editorConfig"
        editor-id="buyInfo"
      ></vue-ueditor-wrap>
    </el-form-item>
  </el-form>
  <div class="displayflex">
    <el-button
      type="primary"
      size="mini"
      style="margin-left: 30px"
      @click="onsubmit"
      >提交</el-button
    >
  </div>
</template>
<script>
// import _ from 'lodash'
export default {
  data() {
    return {
      buyform: {
        caption: '',
        keywords: '',
        content: '',
        pictureUrl1: '',
        pictureUrl2: '',
        pictureUrl3: '',
        linkman: '',
        telephone: '',
        note: '',
        show: true,
        productName: '',
        number: '',
        standard: '',
        region: '',
      },
      rules: {
        caption: [
          { required: true, message: '请输入信息主题！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        keywords: [
          { required: true, message: '请添加关键词！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        linkman: [
          { required: true, message: '请添加联系人！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        telephone: [
          { required: true, message: '请添加联系方式！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      typeoption: [{
        label: '自定义',
        value: 'BANNER'
      }, {
        label: '咨询公告',
        value: 'INFO'
      }, {
        label: '广告',
        value: 'AD'
      }, {
        label: '团购',
        value: 'GROUP'
      }, {
        label: '直播',
        value: 'LIVE'
      }],
      baseUrl: '',
      teamlist: [],
      piclist: [],
      imgBaseUrl: ''
    }
  },
  methods: {
    handleexceed() {
      this.$message.error('仅限上传3个媒体文件！')
    },
    handleRemove(file) {
      console.log(file)
      var i = this.teamlist.findIndex(item => item.url === file.url)
      this.teamlist.splice(i, 1)
      console.log(file.url.split('/'))
      var tmpArr = file.url.split('/')
      var j = this.piclist.findIndex(item => item === tmpArr[tmpArr.length - 1])
      this.piclist.splice(j, 1)
      console.log('绑定list', this.teamlist)
      console.log('提交数组', this.piclist)
    },
    // handlePictureCardPreview(file) {
    //   this.dialogImageUrl = file.url
    // },
    async onsubmit() {
      if (this.piclist.length === 0) {
        this.$message.error('请上传图片！')
        return
      }
      console.log(this.buyform)
      console.log('图片', this.piclist)
      const data = {}
      if (this.$route.params.isadd === 'true') {
        data.caption = this.buyform.caption
        data.keywords = this.buyform.keywords
        data.content = this.buyform.content
        data.linkman = this.buyform.linkman
        data.telephone = this.buyform.telephone
        data.note = this.buyform.note
        data.show = this.buyform.show
        data.productName = this.buyform.productName
        data.number = this.buyform.number
        data.standard = this.buyform.standard
        data.region = this.buyform.region
        data.pictureUrl1 = this.piclist[0]
        data.pictureUrl2 = this.piclist[1] || ''
        data.pictureUrl3 = this.piclist[2] || ''
      } else {
        data.id = this.buyform.id
        data.caption = this.buyform.caption
        data.keywords = this.buyform.keywords
        data.content = this.buyform.content
        data.productName = this.buyform.productName
        data.number = this.buyform.number
        data.standard = this.buyform.standard
        data.region = this.buyform.region
        data.linkman = this.buyform.linkman || ''
        data.telephone = this.buyform.telephone || ''
        data.note = this.buyform.note
        data.show = this.buyform.show
        data.pictureUrl1 = this.piclist[0]
        data.pictureUrl2 = this.piclist[1] || ''
        data.pictureUrl3 = this.piclist[2] || ''
      }

      console.log(data)
      const res = await this.$http.post('/nktdec/purchaseinfo/purchaseinfos', data)
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('信息设置成功！')
        this.$router.push('/buylist')
      }
    },
    async filechange(file, fileList) {
      const formData = new FormData()
      formData.append('file', file.raw)
      formData.append('params', '参数')
      const res = await this.$http(
        {
          method: 'post',
          url: '/nktdec/uploader/uploadfile',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data;',
          }
        }
      )
      console.log(res)
      this.teamlist.push({
        url: this.imgBaseUrl + res.data.result
      })
      // const coverurl = await this.$http.get('/nktdec/uploader/images/' + res.data.result)
      // console.log(this.baseUrl + coverurl.config.url.substring(1))
      // if (!(this.piclist.some(item => item === this.baseUrl + coverurl.config.url.substring(1)))) {
      this.piclist.push(res.data.result)
      // }
      console.log(this.piclist)
    },
  },
  computed: {
    buyInfo() {
      return this.$store.state.buyInfo
    },
    editorConfig() {
      return this.$store.state.editorConfig
    }
  },
  created() {
    this.baseUrl = this.$http.defaults.baseURL
    // this.imgBaseUrl = this.$http.defaults.baseURL + 'nktdec/uploader/images/'
    this.imgBaseUrl = 'http://nktdec.ctvideo.cn/images/'
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '资讯管理',
        index: '/newsmange'
      },
      {
        name: '求购列表',
        index: '/buylist'
      }, {
        name: '求购信息',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    if (this.$route.params.isadd === 'false') {
      this.buyform = this.buyInfo
      console.log(this.buyform)
      this.teamlist.push({
        url: this.imgBaseUrl + this.buyform.pictureUrl1
      })
      this.piclist.push(this.buyform.pictureUrl1)
      if (this.buyform.pictureUrl2) {
        this.teamlist.push({
          url: this.imgBaseUrl + this.buyform.pictureUrl2
        })
        this.piclist.push(this.buyform.pictureUrl2)
      }
      if (this.buyform.pictureUrl3) {
        this.teamlist.push({
          url: this.imgBaseUrl + this.buyform.pictureUrl3
        })
        this.piclist.push(this.buyform.pictureUrl3)
      }
    }
  },
}
</script>
<style scoped>
</style>
