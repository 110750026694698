<template>
  <div style="text-align: left">供应信息</div>
  <div style="display: flex; justify-content: flex-start">
    <el-button
      type="primary"
      size="mini"
      style="margin-top: 10px"
      @click="addpaynews"
      >新增供应信息</el-button
    >
  </div>
  <el-empty v-if="tableData.length === 0" description="暂无数据"></el-empty>

  <el-table
    v-else-if="tableData.length >= 0"
    :data="tableData"
    stripe
    style="width: 100%; margin-top: 30px"
  >
    <el-table-column type="index" label="#"> </el-table-column>
    <el-table-column prop="caption" label="信息标题" width="180px">
    </el-table-column>
    <el-table-column prop="keywords" label="关键字" width="180px">
      <template #default="scope">
        <el-tag>{{ scope.row.keywords }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="pictureUrl1" label="信息图片" width="150px">
      <template #default="scope">
        <img
          :src="imgBaseUrl + scope.row.pictureUrl1"
          alt=""
          style="width: 80px; height: 120px; object-fit: contain"
        />
      </template>
    </el-table-column>
    <el-table-column prop="linkman" label="联系人" width="180px">
    </el-table-column>
    <el-table-column prop="telephone" label="联系电话" width="180px">
    </el-table-column>
    <el-table-column prop="note" label="备注" width="200px"> </el-table-column>
    <el-table-column prop="show" label="是否展示" width="100px">
      <template #default="scope">
        <el-tag v-if="scope.row.show" type="success">展示</el-tag>
        <el-tag v-else type="info">隐藏</el-tag>
      </template>
    </el-table-column>

    <el-table-column prop="address" label="操作" width="200px">
      <template #default="scope">
        <div
          style="display: flex; justify-content: flex-start; flex-wrap: wrap"
        >
          <el-button
            style="margin-right: 10px"
            type="text"
            @click="changeshowstate(scope.row)"
            >{{ scope.row.show ? "隐藏" : "展示" }}</el-button
          >
          <el-button type="text" @click="editpayNews(scope.row)"
            >修改</el-button
          >
          <el-button
            type="text"
            @click="deletepayNews(scope.row)"
            class="marginleft10"
            >删除</el-button
          >
        </div>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 20, 30]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      imgBaseUrl: '',
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
    }
  },
  created() {
    // this.imgBaseUrl = this.$http.defaults.baseURL + 'nktdec/uploader/images/'
    this.imgBaseUrl = 'http://nktdec.ctvideo.cn/images/'

    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '资讯管理',
        index: '/newsmange'
      },
      {
        name: '供应列表',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getpaynews(1)
  },
  mounted() {
    this.$nextTick(() => {
    })
  },
  watch: {
  },
  methods: {
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/supplyinfo/supplyinfos/1' + '?pageSize=' + val)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async handleCurrentChange(val) {
      console.log(val)
      var str = val + '?pageSize=' + this.pageSize
      const res = await this.$http.get('/nktdec/supplyinfo/supplyinfos/' + str)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async getpaynews(pageno) {
      const res = await this.$http.get('/nktdec/supplyinfo/supplyinfos/' + pageno)
      // console.log(res)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      console.log(this.tableData)
    },
    addpaynews() {
      this.$router.push('/paynews/true')
    },
    async changeshowstate(row) {
      console.log(row)
      row.show = !row.show
      const res = await this.$http.post('/nktdec/supplyinfo/supplyinfos', row)
      console.log(res)
      if (res.data.code === '1') this.$message.success('设置成功！')
      this.getpaynews(1)
    },
    editpayNews(row) {
      console.log(row)
      window.sessionStorage.setItem('payInfo', JSON.stringify(row))
      this.$store.commit('setpayInfo', row)
      this.$router.push('/paynews/false')
    },
    async deletepayNews(row) {
      console.log(row)
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/supplyinfo/supplyinfo/' + row.id)
        // if (res.)
        console.log(res)
        if (res.data.code === '1') {
          this.getpaynews(1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
  }
}
</script>
<style scoped>
</style>
